import "./style.scss";

import ProductCard from "../components/ProductCard";
import {
  Box,
  CircularProgress,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";
import Pages from "../..";

import { callHttpClientMethod } from "../../../services/callHttpClient";
import {
  allPlants,
  category,
  listSize,
  productLists,
  special,
} from "../../../services/apiList";
import { BASE_URL, HttpMethod } from "../../../services/api.constant";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { memo, useCallback, useEffect, useState } from "react";
import { Category, Product, Size } from "../../../gobalType";
import SpecialView from "../components/SpecialProduct";
import { useNavigate } from "react-router";
import { theme } from "../../../utils/theme";
import { TypesAction } from "../../../redux/typesAction";

import FilterInMobile from "./FilterInMobile";
import { debounce } from "lodash";

// const getListProduct = debounce(getListAllPlant, 2000);

const PlantsPage = () => {
  const { auth } = useSelector((state: RootState) => state.auth);
  const { product_lists, loadingPf } = useSelector(
    (state: RootState) => state?.products
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataSpecial, setdataSpecial] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<Category[]>([]);
  const [allSize, setAllSize] = useState<Size[]>([]);
  const [arrchoiceSize, setArrchoiceSize] = useState<any>([]);
  const [arrchoicePlant, setArrchoicePlant] = useState<any>([]);

  useEffect(() => {
    getListAllPlant();
  }, [arrchoicePlant, arrchoiceSize]);
  useEffect(() => {
    getListCategory();
    handleSpecialProduct();
    getListSize();
  }, []);

  const handleChoiceAll = (arr: any, type: any) => {
    let arrProduct: any;
    type === "plant" ? (arrProduct = categories) : (arrProduct = allSize);

    if (arr?.length === arrProduct?.length - 1) {
      type === "plant" ? setArrchoicePlant([]) : setArrchoiceSize([]);
    } else {
      var arrNew1 = arrProduct?.filter((item: any) => {
        return item.id !== 0;
      });

      var result = arrNew1.map((item: any) => {
        if (type === "plant") return item.id;
        else return item.value;
      });
      type === "plant" ? setArrchoicePlant(result) : setArrchoiceSize(result);
    }
  };
  const handleChoicePlant = (cate: any) => {
    var index = arrchoicePlant.indexOf(cate.id);
    // Nếu phần tử tồn tại trong mảng, xoá nó
    if (index !== -1) {
      var arrNew = arrchoicePlant.filter(function (item: any) {
        return item !== cate.id;
      });
      setArrchoicePlant(arrNew);
    } else {
      setArrchoicePlant(arrchoicePlant.concat(cate.id));
    }
    // }
  };

  const handleChoiceSize = (cate: any) => {
    var index = arrchoiceSize.indexOf(cate.value);
    // Nếu phần tử tồn tại trong mảng, xoá nó
    if (index !== -1) {
      var arrNew = arrchoiceSize.filter(function (item: any) {
        return item !== cate.value;
      });
      setArrchoiceSize(arrNew);
    } else {
      setArrchoiceSize(arrchoiceSize.concat(cate.value));
    }
  };

  // get value category to choose
  const getListCategory = async () => {
    setLoading(true);
    const respon = await callHttpClientMethod(
      BASE_URL + category(),
      HttpMethod.Get,
      {},
      {}
    );
    setLoading(false);
    setCategories(respon?.data);
  };
  const getListSize = async () => {
    setLoading(true);
    const respon = await callHttpClientMethod(
      BASE_URL + listSize(),
      HttpMethod.Get,
      {},
      {}
    );
    setLoading(false);
    setAllSize(respon?.data);
  };
  const getListAllPlant = async () => {
    let arrPlant, arrSize;
    if (arrchoicePlant.includes(0)) {
      arrPlant = [];
    } else {
      arrPlant = arrchoicePlant;
    }
    if (arrchoiceSize.includes("all")) {
      arrSize = [];
    } else arrSize = arrchoiceSize;

    const data = {
      category_id: arrPlant,
      size_name: arrSize,
    };
    const respon = await callHttpClientMethod(
      BASE_URL + allPlants(),
      HttpMethod.Get,
      data,
      {}
    );
    dispatch({
      type: TypesAction.GET_LIST_PRODUCTS,
      payload: { data: respon, loadingPf: false },
    });
  };

  const handleSpecialProduct = async () => {
    setLoading(true);
    const respon = await callHttpClientMethod(
      BASE_URL + special(),
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    setLoading(false);
    setdataSpecial(respon);
  };

  if (loadingPf || loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: 32,
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  let result;
  product_lists?.length > 0 ? (result = "results") : (result = "result");
  return (
    <Pages>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}>
        <Box
          sx={{
            marginTop: { xs: "65px", sm: "60px" },
            width: {
              xs: "380px",
              sm: "650px",
              md: "800px",
              lg: "981px",
            },
          }}>
          <Box
            className="info-products"
            sx={{
              display: { sm: "flex" },
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
            <FilterInMobile
              all_size={allSize}
              arrchoicePlant={arrchoicePlant}
              arrchoiceSize={arrchoiceSize}
              categories={categories}
              handleChoiceAll={handleChoiceAll}
              handleChoicePlant={handleChoicePlant}
              handleChoiceSize={handleChoiceSize}
            />
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                borderRadius: "3px",
                marginTop: { xs: "65px", sm: "0px" },
                backgroundColor: "#fff",
                width: { sm: "200px" },
                padding: "20px 0 20px 15px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                ml: { md: -10, lg: -15 },
                mr: 2,
              }}>
              <Typography
                sx={{ fontWeight: "600", marginBottom: 0, color: "#000" }}>
                Filter by
              </Typography>

              <Box>
                <Typography
                  sx={{
                    margin: "5px 0 8px",
                    color: "#4e4747",
                    fontWeight: "600",
                  }}>
                  Categories
                </Typography>
                {categories?.map((cate, index) => {
                  return (
                    <Box
                      onClick={() => {
                        cate.id === 0
                          ? handleChoiceAll(arrchoicePlant, "plant")
                          : handleChoicePlant(cate);
                      }}
                      key={index}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                        backgroundColor:
                          arrchoicePlant.includes(cate?.id) ||
                          arrchoicePlant.length === categories.length - 1
                            ? "#E7F3E7"
                            : "#fff",
                      }}>
                      <Checkbox
                        checked={
                          arrchoicePlant.includes(cate?.id) ||
                          arrchoicePlant.length === categories.length - 1
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: { xs: 18, sm: 23 },
                            color:
                              arrchoicePlant.includes(cate?.id) ||
                              arrchoicePlant.length === categories.length - 1
                                ? `${theme.palette.common.green}`
                                : "#979797",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          marginBottom: 0,
                          color:
                            arrchoicePlant.includes(cate?.id) ||
                            arrchoicePlant.length === categories.length - 1
                              ? " #263238"
                              : " #979797",
                        }}>
                        {cate?.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ borderBottom: "1px #ccc solid", my: 1 }}></Box>
              <Box>
                <Typography
                  sx={{ my: "10px", color: "#4e4747", fontWeight: "600" }}>
                  Plants Size
                </Typography>
                {allSize?.map((size, index) => {
                  return (
                    <Box
                      onClick={() => {
                        size.value === "all"
                          ? handleChoiceAll(arrchoiceSize, "size")
                          : handleChoiceSize(size);
                      }}
                      key={index}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                        backgroundColor:
                          arrchoiceSize.includes(size?.value) ||
                          arrchoiceSize?.length === allSize?.length - 1
                            ? "#E7F3E7"
                            : "#fff",
                      }}>
                      <Checkbox
                        checked={
                          arrchoiceSize.includes(size?.value) ||
                          arrchoiceSize?.length === allSize?.length - 1
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: { xs: 18, sm: 23 },
                            color:
                              arrchoiceSize.includes(size?.value) ||
                              arrchoiceSize?.length === allSize?.length - 1
                                ? `${theme.palette.common.green}`
                                : "#979797",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          marginBottom: 0,
                          color:
                            arrchoiceSize.includes(size?.value) ||
                            arrchoiceSize.length === allSize?.length - 1
                              ? " #263238"
                              : " #979797",
                        }}>
                        {size?.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px -5px 6px",
                  padding: "10px 10px 0px 20px",
                  marginLeft: "-19px",
                }}>
                {arrchoicePlant.length > 0 || arrchoiceSize.length > 0 ? (
                  <Typography sx={{ marginBottom: 0 }}>
                    {product_lists?.length} {result}
                  </Typography>
                ) : (
                  <Typography sx={{ marginBottom: 0 }}>0 result</Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
              <ProductCard />
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: "600",
                marginBottom: "0px",
                mt: 5,
                fontSize: { xs: 16, sm: 25 },
                textTransform: "uppercase",
                color: "#50555C",
              }}
              textAlign={"center"}>
              TBT Specialty plants
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                // width: { xs: "28%", sm: "70%" }
              }}>
              <SpecialView dataSpecials={dataSpecial} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Pages>
  );
};

export default memo(PlantsPage);
